@keyframes slide-in-top {
	0% {
		-webkit-transform: translateY(-100px);
		transform: translateY(-100px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes slide-out-top {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateY(-100px);
		transform: translateY(-100px);
		opacity: 0;
		display: none;
	}
}

@keyframes slide-in-bottom {
	0% {
		-webkit-transform: translateY(1000px);
		transform: translateY(1000px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes slide-out-bottom {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateY(1000px);
		transform: translateY(1000px);
		opacity: 0;
		display: none;
	}
}

@keyframes slide-in-blurred-right {
	0% {
		-webkit-transform: translateX(500px) scaleX(2.5) scaleY(0.2);
		transform: translateX(500px) scaleX(2.5) scaleY(0.2);
		-webkit-transform-origin: 1% 50%;
		transform-origin: 1% 50%;
		-webkit-filter: blur(40px);
		filter: blur(40px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateX(0) scaleY(1) scaleX(1);
		transform: translateX(0) scaleY(1) scaleX(1);
		-webkit-transform-origin: 50% 50%;
		transform-origin: 50% 50%;
		-webkit-filter: blur(0);
		filter: blur(0);
		opacity: 1;
	}
}

@keyframes slide-in-blurred-left {
	0% {
		-webkit-transform: translateX(-500px) scaleX(2.5) scaleY(0.2);
		transform: translateX(-500px) scaleX(2.5) scaleY(0.2);
		-webkit-transform-origin: 100% 50%;
		transform-origin: 100% 50%;
		-webkit-filter: blur(40px);
		filter: blur(40px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateX(0) scaleY(1) scaleX(1);
		transform: translateX(0) scaleY(1) scaleX(1);
		-webkit-transform-origin: 50% 50%;
		transform-origin: 50% 50%;
		-webkit-filter: blur(0);
		filter: blur(0);
		opacity: 1;
	}
}

@keyframes slide-in-blurred-top {
	0% {
		-webkit-transform: translateY(-500px) scaleY(2.5) scaleX(0.2);
		transform: translateY(-500px) scaleY(2.5) scaleX(0.2);
		-webkit-transform-origin: 50% 0%;
		transform-origin: 50% 0%;
		-webkit-filter: blur(40px);
		filter: blur(40px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0) scaleY(1) scaleX(1);
		transform: translateY(0) scaleY(1) scaleX(1);
		-webkit-transform-origin: 50% 50%;
		transform-origin: 50% 50%;
		-webkit-filter: blur(0);
		filter: blur(0);
		opacity: 1;
	}
}
