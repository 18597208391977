@import "src/app/styles/index.scss";

.content {
	display: flex;
	align-items: start;
	justify-content: space-between;
	gap: 20px;

	color: $btnBg;

	font-family: $mainFontFamily;

	max-height: 88vh;
	max-width: 90vw;
	width: 100%;

	overflow-y: auto;

    padding-right: 10px;

	@include mediaMax(592px) {
		flex-wrap: wrap;
		gap: 0;
	}

	&::-webkit-scrollbar {
		width: 4px;
		height: 6px;
	}

	&::-webkit-scrollbar-track {
		background: $scrollBarTrack;
		border-radius: 30px;
	}

	&::-webkit-scrollbar-thumb {
		background: $scrollBarThumb;
		border-radius: 30px;
	}
}

.block {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;

	width: 100%;
}

.title {
	margin-bottom: 5px;
}

.btn {
	width: 145px;
	height: 44px;
	background: $btnBgBalls;
	border-radius: 32px;
	border: 2px $btnBg solid;

	display: grid;
	justify-content: center;
	align-items: center;

	grid-auto-flow: column;
	column-gap: 8px;

	font-family: $mainFontFamily;
	color: $btnBg;
}

.input {
	width: 100%;
	height: 48px;
	background: transparent;
	outline: none;

	padding: 0 29px;
	font-family: $subMainFontFamily;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;

	color: $btnBg;

	border-radius: 32px;
	border: 2px $btnBg solid;
	background: $colorMainText;

	margin-bottom: 5px;
}