@import "src/app/styles/index.scss";

.content {
	display: flex;
	//flex-wrap: wrap;
	align-items: start;
	justify-content: start;
	gap: 20px;

	color: $btnBg;
	font-family: $mainFontFamily;

	background-image: url("../../../common/assets/img/png/background.png");
	background-size: cover;
	background-repeat: no-repeat;

	overflow: auto;

	min-height: 100vh;
}

.tabs {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 14px;
	
	margin-top: 50px;
	padding: 20px;
}

.tab {
	& > button {
		cursor: pointer;

		width: 163px;
		height: 30px;

		background: $btnBg;
		border: none;
		border-radius: 10px;

		color: $colorMainText;
		font-family: $mainFontFamily;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;

		&:hover {
			background: rgba(255, 255, 0, 0.6);

			color: $btnBg;
		}
	}
}

.mainContent {
	max-width: calc(100vh - 120px);
}
