@import "src/app/styles/index.scss";

.content {
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	color: $btnBg;

	padding: 20px;

	font-family: $mainFontFamily;
}

.res {
}

.areas {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	column-gap: 16px;
	row-gap: 10px;

	margin-bottom: 16px;

	width: 793px;
}

.block {
	margin-top: 50px;
	margin-bottom: 20px;
}

.title {
	margin-bottom: 5px;
}

.btn {
	width: 145px;
	height: 44px;
	background: $btnBgBalls;
	border-radius: 32px;
	border: 2px $btnBg solid;

	display: grid;
	justify-content: center;
	align-items: center;

	grid-auto-flow: column;
	column-gap: 8px;

	font-family: $mainFontFamily;
	color: $btnBg;

    cursor: pointer;
}

.input {
	width: 100%;
	height: 48px;
	background: transparent;
	outline: none;

	padding: 0 29px;
	font-family: $subMainFontFamily;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;

	color: $btnBg;

	border-radius: 32px;
	border: 2px $btnBg solid;
	background: $colorMainText;
}

.table {
	overflow-y: auto;
	overflow-x: auto;
	max-height: 80vh;
	width: 80vw;
	margin-bottom: 10px;

	thead > tr > td {
		border: 2px $btnBg solid;

		padding: 5px;

		font-weight: 600;

		& > img {
			width: 100px;
		}
	}

	tbody > tr > td {
		border: 2px $btnBg solid;

		padding: 5px;
	}

	tbody > tr > td > img {
		width: 100px;
	}
}

.select {
	position: relative;

	font-family: $subMainFontFamily;
	font-size: 16px;
	font-weight: 500;
	line-height: normal;
	text-transform: capitalize;
	z-index: 1;

	margin-bottom: 27px;
}

.selectBc {
	display: flex;
	align-items: center;
	gap: 14px;
	cursor: pointer;

	border-radius: 32px;
	background: $colorMainText;

	width: 224px;
	height: 49px;

	padding: 10px;

	position: relative;

	& > img {
		width: 38px;
		height: 38px;
		border-radius: 38px;
	}
}

.selectBcValue {
	width: 220px;
	$btnBg-space: nowrap;
}

.selectBcArrow {
	transition: 0.5s;

	&.active {
		transform: rotate(180deg);
	}
}

.optionsSelectBc {
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 10;

	top: 57px;
	left: -2px;
	position: absolute;

	border-radius: 10px;
	background: $colorMainText;

	padding: 5px 0;

	& > div:nth-child(1) {
		font-weight: 700;
	}
}

.optionSelectBc {
	cursor: pointer;
	width: 224px;
	height: 49px;

	display: flex;
	align-items: center;
	gap: 14px;

	padding: 10px;

	&:hover {
		background: $tabsBgHoverBalls;
	}

	& > img {
		width: 38px;
		height: 38px;
		border-radius: 38px;
	}
}

.disabled {
	pointer-events: none;
	opacity: 0.5;
}

.bottom {
	display: flex;
	align-items: center;
	gap: 16px;

	& > div {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;

		& > input {
			width: 30px;
			height: 30px;
		}
	}
}

.message {
	width: 500px;
	height: 100px;

	padding-top: 10px;
	padding-bottom: 10px;
}