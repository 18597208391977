@import "src/app/styles/index.scss";

.content {
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	color: $btnBg;

	padding: 20px;

	font-family: $mainFontFamily;
}

.areas {
	display: flex;
	flex-wrap: wrap;
	column-gap: 16px;
	row-gap: 10px;

	margin-bottom: 16px;

	width: 793px;
}

.block {
	margin-top: 50px;
	margin-bottom: 20px;
}

.title {
	margin-bottom: 5px;
}

.btn {
	width: 145px;
	height: 44px;
	background: $btnBgBalls;
	border-radius: 32px;
	border: 2px $btnBg solid;

	display: grid;
	justify-content: center;
	align-items: center;

	grid-auto-flow: column;
	column-gap: 8px;

	font-family: $mainFontFamily;
	color: $btnBg;
	
    cursor: pointer;
}

.input {
	width: 100%;
	height: 48px;
	background: transparent;
	outline: none;

	padding: 0 29px;
	font-family: $subMainFontFamily;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;

	color: $btnBg;

	border-radius: 32px;
	border: 2px $btnBg solid;
	background: $colorMainText;
}

.table {
	overflow-y: auto;
	overflow-x: auto;
	max-height: 80vh;
	width: 80vw;
	margin-bottom: 10px;

	thead > tr > td {
		border: 2px $btnBg solid;

		padding: 5px;

		font-weight: 600;

		& > img {
			width: 100px;
		}
	}

	tbody > tr > td {
		border: 2px $colorMainText solid;

		padding: 5px;
	}

	tbody > tr > td > img {
		width: 100px;
	}
}

.bottom {
	display: flex;
	align-items: center;
	gap: 16px;

	& > div {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;

		& > input {
			width: 30px;
			height: 30px;
		}
	}
}