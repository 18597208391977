body {
	margin: 0;
	/* Background: #000; */
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	/* overflow: hidden; */
	height: 100%;
}

html,
body,
img,
div,
a,
table {
	image-rendering: -webkit-optimize-cotrast !important;
}

img {
	user-select: none;
}

.nws-modal-wrapper.open {
	z-index: 1001;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

#root {
	height: 100%;
}

html {
	height: 100%;
}

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");

@font-face {
	font-family: mainfont;
	src: url("./assets/fonts/mainfont.ttf");
	font-style: normal;
}

.account-iframe {
	display: none;
}

:root {
	--toastify-color-light: #fff;
	--toastify-color-dark: #121212;
	--toastify-color-info: #3498db;
	--toastify-color-success: #07bc0c;
	--toastify-color-warning: #f1c40f;
	--toastify-color-error: #e74c3c;
	--toastify-color-transparent: #fff;

	--toastify-icon-color-info: var(--toastify-color-info);
	--toastify-icon-color-success: var(--toastify-color-success);
	--toastify-icon-color-warning: var(--toastify-color-warning);
	--toastify-icon-color-error: var(--toastify-color-error);

	--toastify-toast-width: 420px;
	--toastify-toast-background: #fff;
	--toastify-toast-min-height: 64px;
	--toastify-toast-max-height: 800px;
	--toastify-font-family: sans-serif;
	--toastify-z-index: 9999;

	--toastify-text-color-light: #fff;
	--toastify-text-color-dark: #fff;

	//Used only for colored theme
	--toastify-text-color-info: #fff;
	--toastify-text-color-success: #fff;
	--toastify-text-color-warning: #fff;
	--toastify-text-color-error: #fff;

	--toastify-spinner-color: #616161;
	--toastify-spinner-color-empty-area: #e0e0e0;

	// Used when no type is provided
	// toast("**hello**")
	--toastify-color-progress-light: linear-gradient(
									to right,
									#4cd964,
									#5ac8fa,
									#007aff,
									#34aadc,
									#5856d6,
									#ff2d55
	);
	// Used when no type is provided
	--toastify-color-progress-dark: #bb86fc;
	--toastify-color-progress-info: var(--toastify-color-info);
	--toastify-color-progress-success: var(--toastify-color-success);
	--toastify-color-progress-warning: var(--toastify-color-warning);
	--toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast {
	background: linear-gradient(#16132c 0 0) padding-box,
		/*this is your grey Background*/
	linear-gradient(198.57deg, #ebb16d 12.58%, #d67300 113.05%) border-box;
	border-radius: 5px;
	padding: 10px;
	border: 2px solid transparent;
}

.Toastify__toast-container {
	width: auto;
}